.pentest-updates-md {
  li {
    @apply text-hard-grey;
  }

  code {
    @apply text-red bg-red bg-opacity-5;
  }

  pre > code {
    @apply text-dark-blue;
  }
}
